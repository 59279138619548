<template>
	<div id="cpm_couponList">
		<div class="el-content mb12">
			<a-space>
				<a-input-search v-model:value="search.key" 
					placeholder="名称" 
					style="width: 400px;" 
					enter-button 
					@search="getCouponList(1,info.limit)" 
				/>
				<router-link :to="{path:isShop == 0 ?'/marketing/couponEdit':'/many_store/marketing/couponEdit'}">
					<a-button type="primary" v-has="{action:'/marketing/couponEdit',plat:isShop}">
						<i class="ri-add-line ri-lg ri-top"></i>
						新增优惠券
					</a-button>
				</router-link>
				<a-button type="danger" @click="deleteCoupon(pks)">批量删除</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-tabs v-model:activeKey="search.type" @change="getCouponList(1,info.limit)">
				<a-tab-pane key="1" tab="商城"></a-tab-pane>
				<a-tab-pane key="3" tab="认养"></a-tab-pane>
				<a-tab-pane key="4" tab="土地"></a-tab-pane>
			</a-tabs>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'名称',dataIndex:'coupon_name'},
				{title:'最低消费（元）',dataIndex:'low_cash_price'},
				{title:'优惠金额（元）',dataIndex:'coupon_price'},
				{title:'有效期',dataIndex:'expiry_day',slots:{customRender:'expiry_day'}},
				{title:'是否发放',dataIndex:'coupon_status',slots:{customRender:'coupon_status'}},
				{title:'状态',dataIndex:'is_expire',slots:{customRender:'is_expire'}},
				{title:'数量',dataIndex:'count',slots:{customRender:'count'}},
				{title:'排序',dataIndex:'rank'},
				{title:'最后更新时间',dataIndex:'update_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
				<template #expiry_day="{record}">
					<span v-if="record.expiry_date==1">{{record.expiry_day}}天</span>
					<span v-if="record.expiry_date==2">{{record.begin_time}}~{{record.end_time}}</span>
				</template>
				<template #coupon_status="{record}">
					<a-tag color="#00CC66"  v-if="record.coupon_status==1">已发放</a-tag>
					<a-tag type="#999" v-if="record.coupon_status==0">未发放</a-tag>
				</template>
				<template #is_expire="{record}">
					<a-tag color="#FF0066" v-if="record.is_expire==1">已过期</a-tag>
					<a-tag color="#00CC66" v-else>正常可用</a-tag>
				</template>
				<template #count="{record}">
					<div><span class="f12">库存：</span>{{record.count}}张</div>
					<div><span class="f12">已发放：</span>{{record.use_count}}张</div>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:isShop == 0 ?'/marketing/couponEdit':'/many_store/marketing/couponEdit',query:{id:record.id}}">
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'/marketing/couponEdit'}">
							</kd-button>
						</router-link>
						<kd-button type="danger" 
							title="删除" 
							icon="ri-delete-bin-5-line" 
							v-has="{action:'wxapp_marketing_coupon_del'}"
							@click="deleteCoupon(record.id)">
						</kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getCouponList(info.page,e)}"
					@change="(e)=>{getCouponList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { onActivated, reactive, toRefs } from 'vue';
import couponModel from '@/api/addons/coupon'
import common from '@/api/common';
export default {
	name:'page-coupon-list',
	props:{
		isShop:Number
	},
	setup(){
		const state = reactive({
			search:{key:'',type:'1'},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			pks:[],
		})	
		
		onActivated(()=>{
			getCouponList(state.info.page,state.info.limit)
		})
		getCouponList(1,state.info.limit)
		function getCouponList(page,limit){
			couponModel.getCoupon(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const deleteCoupon = (id)=>common.deleteDataList(id,15,"确认删除该优惠券吗?").then(()=>{
			getCouponList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getCouponList,
			deleteCoupon
		}
	}
}
</script>

<style>
</style>
