<template>
	<div id="couponList">
		<page-coupon-list :is-shop="0"></page-coupon-list>
	</div>
</template>

<script>
import PageCouponList from '@/components/layout/coupon/page-coupon-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default {
	name:"CouponList",
	components:{
		PageCouponList
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/marketing/couponEdit' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style>
</style>
